import React from 'react';
import PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import styles from './blogTemplate.module.css';

export default function BlogTemplate({data}) {
  const {markdownRemark} = data; // data.markdownRemark holds our post data
  const {frontmatter, html} = markdownRemark;

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
      />
      <div className="blog-post-container">
        <div>
          <Link to="/blog">Back to posts...</Link>
        </div>
        <div className="blog-post">
          <div className={styles.title}>{frontmatter.title}</div>
          <div className={styles.date}>{frontmatter.date}</div>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{__html: html}}
          />
        </div>
      </div>
    </Layout>
  );
}

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
      }
    }
  }
`;
